import { Calendar } from '@fullcalendar/core'
import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

const Colors = () => import('@/views/theme/Colors')
const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/charts/Charts')
const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Forms = () => import('@/views/base/Forms')
const Switches = () => import('@/views/base/Switches')
const Tables = () => import('@/views/base/Tables')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
const Brands = () => import('@/views/icons/Brands')
const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')

// Views - settings
const VendorType = () => import('@/views/settings/vendor_type/index.vue')
const BookingType = () => import('@/views/settings/booking_type/index.vue')
const BookingItemType = () => import('@/views/settings/booking_item_type/index.vue')
const CommodityType = () => import('@/views/settings/commodity_type/index.vue')
const VehicleType = () => import('@/views/settings/vehicle_type/index.vue')
const Depot = () => import('@/views/settings/depot/index.vue')
const Origins = () => import('@/views/settings/origins/index.vue')
const Destinations = () => import('@/views/settings/destination/index.vue')
const MakerModel = () => import('@/views/settings/maker_model/index.vue')
const VehicleModel = () => import('@/views/settings/vehicle_model/index.vue')
const PartCategory = () => import('@/views/settings/part_category/index.vue')
const PartLocation = () => import('@/views/settings/part_location/index.vue')
const UserType = () => import('@/views/settings/user_type/index.vue')
const Permission = () => import('@/views/settings/user_type/permission.vue')
const Customer = () => import('@/views/data/customer/index.vue')
const Vendor = () => import('@/views/data/vendor/index.vue')
const CreateVendor = () => import('@/views/data/vendor/create.vue')
const UpdateVendor = () => import('@/views/data/vendor/create.vue')
const ViewVendor = () => import('@/views/data/vendor/view.vue')
const CreatePersonnel = () => import('@/views/data/personnel/create.vue')
const UpdatePersonnel = () => import('@/views/data/personnel/create.vue')
const ViewPersonnel = () => import('@/views/data/personnel/view.vue')
const Personnel = () => import('@/views/data/personnel/index.vue')
const PersonnelRate = () => import('@/views/data/personnel_rate/index.vue')
const Company = () => import('@/views/data/company/index.vue')
const ClientRate = () => import('@/views/data/client_trucker_rate/index.vue')
const Vehicle = () => import('@/views/data/vehicle/index.vue')
const CreateVehicle = () => import('@/views/data/vehicle/create.vue')
const UpdateVehicle = () => import('@/views/data/vehicle/create.vue')
const ViewVehicle = () => import('@/views/data/vehicle/view.vue')
const Odometer = () => import('@/views/data/vehicle/odometer_data.vue')
const FuelRecord = () => import('@/views/data/fuel_record/index.vue')
const RenewalReminder = () => import('@/views/data/renewal_reminder/index.vue')
const ServiceReminder = () => import('@/views/data/service_reminder/index.vue')
const LicenseRenewalReminder = () => import('@/views/data/license_renewal_reminder/index.vue')
const CreateLicenseRenewalReminder = () => import('@/views/data/license_renewal_reminder/create.vue')
const UpdateLicenseRenewalReminder = () => import('@/views/data/license_renewal_reminder/create.vue')
const ViewLicenseRenewalReminder = () => import('@/views/data/license_renewal_reminder/view.vue')
const PersonnelType = () => import('@/views/settings/personnel_type/index.vue')
const Reports = () => import('@/views/reports/index.vue')
// const Booking = () => import('@/views/booking/index.vue')
// const Booking = () => import('@/views/bookingv2/index.vue')
const CreateBooking = () => import('@/views/booking/create.vue')
// const ViewBooking = () => import('@/views/bookingv2/view.vue')
const BookingV2 = () => import('@/views/bookingv2/index.vue')
const ViewBookingV2 = () => import('@/views/bookingv2/view.vue')
// const ViewBooking = () => import('@/views/booking/view.vue')
const UpdateBooking = () => import('@/views/booking/create.vue')
const ReportBooking = () => import('@/views/booking/report.vue')
const ClientBilling = () => import('@/views/billing_management/client_billing/index.vue')
const CreateClientBilling = () => import('@/views/billing_management/client_billing/create.vue')
const ViewClientBilling = () => import('@/views/billing_management/client_billing/view.vue')
const ClientBillingPayment = () => import('@/views/billing_management/client_billing/payment_receive.vue')
const UpdateClientBilling = () => import('@/views/billing_management/client_billing/create.vue')
const TruckerSettlement = () => import('@/views/billing_management/trucker_settlement/index.vue')
const CreateTruckerSettlement = () => import('@/views/billing_management/trucker_settlement/create.vue')
const ViewTruckerSettlement = () => import('@/views/billing_management/trucker_settlement/view.vue')
const UpdateTruckerSettlement = () => import('@/views/billing_management/trucker_settlement/create.vue')
const Equipment = () => import('@/views/asset_management/equipment/index.vue')
const CreateEquipment = () => import('@/views/asset_management/equipment/create.vue')
const ViewEquipment = () => import('@/views/asset_management/equipment/view.vue')
const UpdateEquipment = () => import('@/views/asset_management/equipment/create.vue')
const Furnishing = () => import('@/views/asset_management/furnishing/index.vue')
const CreateFurnishing = () => import('@/views/asset_management/furnishing/create.vue')
const ViewFurnishing = () => import('@/views/asset_management/furnishing/view.vue')
const UpdateFurnishing = () => import('@/views/asset_management/furnishing/create.vue')
const Incident = () => import('@/views/incident/index.vue')
const CreateIncident = () => import('@/views/incident/incident.vue')
const ViewIncident = () => import('@/views/incident/view.vue')
const UpdateIncident = () => import('@/views/incident/create.vue')
// const ViewPersonnel = () => import('@/views/data/personnel/view.vue')
const DeliveryReceipt = () => import('@/views/booking/delivery_receipt/index.vue')
const CreateDeliveryReceipt = () => import('@/views/booking/delivery_receipt/create.vue')
const ViewDeliveryReceipt = () => import('@/views/booking/delivery_receipt/view.vue')
const UpdateDeliveryReceipt = () => import('@/views/booking/delivery_receipt/create.vue')
const MaterialTransfer = () => import('@/views/booking/material_transfer/index.vue')
const CreateMaterialTransfer = () => import('@/views/booking/material_transfer/create.vue')
const ViewMaterialTransfer = () => import('@/views/booking/material_transfer/view.vue')
const UpdateMaterialTransfer = () => import('@/views/booking/material_transfer/create.vue')
const UserComp = () => import('@/views/data/user/index.vue')
const ViewProfile = () => import('@/views/account/profile/index.vue')
const UpdateProfile = () => import('@/views/account/profile/update.vue')
const ReportEquipment = () => import('@/views/reports/equipment_report.vue')
const ReportBookingCharges = () => import('@/views/reports/booking_charges_report.vue')
const ReportOpex = () => import('@/views/reports/opex_report.vue')
const ReportDr = () => import('@/views/reports/dr_report.vue')
const ReportMts = () => import('@/views/reports/mts_report.vue')
const ReportVehicle = () => import('@/views/reports/vehicle_report.vue')
const ReportFurnishing = () => import('@/views/reports/furnishing_report.vue')
const ReportWorkOrder = () => import('@/views/reports/work_order_report.vue')
const ReportClientBillingSummary = () => import('@/views/reports/clientBillingSummary.vue')
const PartsInventory = () => import('@/views/parts_inventory/index.vue')
const CreatePartsInventory = () => import('@/views/parts_inventory/create.vue')
const UpdatePartsInventory = () => import('@/views/parts_inventory/create.vue')
const ViewPartsInventory = () => import('@/views/parts_inventory/view.vue')
const WorkOrder = () => import('@/views/work_order/index.vue')
const CreateWorkOrder = () => import('@/views/work_order/work_order.vue')
const UpdateWorkOrder = () => import('@/views/work_order/create.vue')
const UpdateWorkOrderTrucking = () => import('@/views/work_order/create_trucking_form.vue')
const ViewWorkOrder = () => import('@/views/work_order/view.vue')
const ViewWorkOrderTrucking = () => import('@/views/work_order/view_trucking.vue')
const RenewalTask = () => import('@/views/settings/renewal_task/index.vue')
const ServiceTask = () => import('@/views/settings/service_task/index.vue')
const LicenseRenewalTask = () => import('@/views/settings/license_renewal_task/index.vue')
const ReportPersonnelSettlement = () => import('@/views/reports/personnel_settlement_report.vue')
const HomeComponent = () => import('@/views/home/home.vue')
const Adjustment = () => import('@/views/accounting_management/adjustment/index.vue')
const WorkingCalendar = () => import('@/views/accounting_management/working_calendar/index.vue')
const Month13 = () => import('@/views/accounting_management/13_month/index.vue')
const View13thMonth = () => import('@/views/accounting_management/13_month/view.vue')
const SSSTable = () => import('@/views/accounting_management/SSS_table/index.vue')
const PLedger = () => import('@/views/accounting_management/personnel_ledger/index.vue')
const ViewPledger = () => import('@/views/accounting_management/personnel_ledger/view.vue')
const ViewPayment = () => import('@/views/accounting_management/personnel_ledger/payment.vue')
const Payroll = () => import('@/views/accounting_management/payroll/index.vue')
const NewPayroll = () => import('@/views/accounting_management/payroll/new_payroll.vue')
// const ViewPayroll = () => import('@/views/accounting_management/payroll/view.vue')
const ViewPayrollPersonnel = () => import('@/views/accounting_management/payroll/view_personnel_payroll.vue')
const Overtime = () => import('@/views/accounting_management/overtime_late/index.vue')
const ViewOvertime = () => import('@/views/accounting_management/overtime_late/view.vue')
// const CreateAdjustment = () => import('@/views/accounting_management/adjustment/create.vue')
// const ViewAdjustment = () => import('@/views/accounting_management/adjustment/view.vue')
// const UpdateAdjustment = () => import('@/views/accounting_management/adjustment/create.vue')
const AuditLog = () => import('@/views/audit-logs/AuditLog.vue');



Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/home',
      redirect: '/home/statistics',
      name: 'Home',
      component:  TheContainer,
      beforeEnter: (to, from, next) => {
        if(window.localStorage.getItem('user_data'))
          next();
        else
        next({name: 'Login'})
      },
      children: [
        {
          path: 'statistics',
          name: 'Statistics',
          component: HomeComponent
        },
      ]
    },
    {
      path: '/',
      redirect: '/dashboard',
      beforeEnter: (to, from, next) => {
        if(window.localStorage.getItem('user_data'))
          next();
        else
        next({name: 'Login'})
      },
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'theme',
          redirect: '/theme/colors',
          name: 'Theme',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'colors',
              name: 'Colors',
              component: Colors
            },
            {
              path: 'typography',
              name: 'Typography',
              component: Typography
            }
          ]
        },
        {
          path: 'charts',
          name: 'Charts',
          component: Charts
        },
        {
          path: 'widgets',
          name: 'Widgets',
          component: Widgets
        },
        {
          path: 'users',
          meta: {
            label: 'Users'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Users',
              component: Users
            },
            {
              path: ':id',
              meta: {
                label: 'User Details'
              },
              name: 'User',
              component: User
            }
          ]
        },
        {
          path: 'base',
          redirect: '/base/cards',
          name: 'Base',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'cards',
              name: 'Cards',
              component: Cards
            },
            {
              path: 'forms',
              name: 'Forms',
              component: Forms
            },
            {
              path: 'switches',
              name: 'Switches',
              component: Switches
            },
            {
              path: 'tables',
              name: 'Tables',
              component: Tables
            },
            {
              path: 'tabs',
              name: 'Tabs',
              component: Tabs
            },
            {
              path: 'breadcrumbs',
              name: 'Breadcrumbs',
              component: Breadcrumbs
            },
            {
              path: 'carousels',
              name: 'Carousels',
              component: Carousels
            },
            {
              path: 'collapses',
              name: 'Collapses',
              component: Collapses
            },
            {
              path: 'jumbotrons',
              name: 'Jumbotrons',
              component: Jumbotrons
            },
            {
              path: 'list-groups',
              name: 'List Groups',
              component: ListGroups
            },
            {
              path: 'navs',
              name: 'Navs',
              component: Navs
            },
            {
              path: 'navbars',
              name: 'Navbars',
              component: Navbars
            },
            {
              path: 'paginations',
              name: 'Paginations',
              component: Paginations
            },
            {
              path: 'popovers',
              name: 'Popovers',
              component: Popovers
            },
            {
              path: 'progress-bars',
              name: 'Progress Bars',
              component: ProgressBars
            },
            {
              path: 'tooltips',
              name: 'Tooltips',
              component: Tooltips
            }
          ]
        },
        {
          path: 'buttons',
          redirect: '/buttons/standard-buttons',
          name: 'Buttons',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'standard-buttons',
              name: 'Standard Buttons',
              component: StandardButtons
            },
            {
              path: 'button-groups',
              name: 'Button Groups',
              component: ButtonGroups
            },
            {
              path: 'dropdowns',
              name: 'Dropdowns',
              component: Dropdowns
            },
            {
              path: 'brand-buttons',
              name: 'Brand Buttons',
              component: BrandButtons
            }
          ]
        },
        {
          path: 'icons',
          redirect: '/icons/coreui-icons',
          name: 'CoreUI Icons',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'coreui-icons',
              name: 'Icons library',
              component: CoreUIIcons
            },
            {
              path: 'brands',
              name: 'Brands',
              component: Brands
            },
            {
              path: 'flags',
              name: 'Flags',
              component: Flags
            }
          ]
        },
        {
          path: 'notifications',
          redirect: '/notifications/alerts',
          name: 'Notifications',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'alerts',
              name: 'Alerts',
              component: Alerts
            },
            {
              path: 'badges',
              name: 'Badges',
              component: Badges
            },
            {
              path: 'modals',
              name: 'Modals',
              component: Modals
            }
          ]
        }
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    },
    {
      path: '/account',
      redirect: '/account',
      name: 'Account',
      beforeEnter: (to, from, next) => {
        if(window.localStorage.getItem('user_data'))
          next();
        else
        next({name: 'Login'})
      },
      component:  TheContainer,
      children: [
        {
          path: 'profile',
          name: 'View Profile',
          component: ViewProfile
        },
        {
          path: 'update_profile',
          name: 'Update Profile',
          component: UpdateProfile
        },
      ]
    },
    {
      path: '/data',
      redirect: '/data',
      name: 'Data',
      beforeEnter: (to, from, next) => {
        if(window.localStorage.getItem('user_data'))
          next();
        else
        next({name: 'Login'})
      },
      component:  TheContainer,
      children: [
        {
          path: 'vehicle',
          name: 'Vehicle',
          component: Vehicle
        },
        {
          path: 'odometer/:id',
          name: 'Odometer',
          component: Odometer
        },
        {
          path: 'fuel_record',
          name: 'FuelRecord',
          component: FuelRecord
        },
        {
          path: 'renewal_reminder',
          name: 'RenewalReminder',
          component: RenewalReminder
        },
        {
          path: 'service_reminder',
          name: 'ServiceReminder',
          component: ServiceReminder
        },
        {
          path: 'license_renewal_reminder',
          name: 'LicenseRenewalReminder',
          component: LicenseRenewalReminder
        },
        {
          path: 'create_license_renewal',
          name: 'Create License Renewal Reminder',
          component: CreateLicenseRenewalReminder
        }, 
        {
          path: 'update_license_renewal/:id',
          name: 'Update License Renewal Reminder',
          component: UpdateLicenseRenewalReminder
        }, 
        {
          path: 'view_license_renewal/:id',
          name: 'ViewLicense Renewal Reminder',
          component: ViewLicenseRenewalReminder
        }, 
        {
          path: 'create_vehicle',
          name: 'Create Vehicle',
          component: CreateVehicle
        }, 
        {
          path: 'update_vehicle/:id',
          name: 'Update Vehicle',
          component: UpdateVehicle
        }, 
        {
          path: 'view_vehicle/:id',
          name: 'View Vehicle',
          component: ViewVehicle
        },
        {
          path: 'customer',
          name: 'Customer',
          component: Customer
        },
        {
          path: 'vendor',
          name: 'Vendor',
          component: Vendor
        },
        {
          path: 'create_vendor',
          name: 'Create Vendor',
          component: CreateVendor
        }, 
        {
          path: 'update_vendor/:id',
          name: 'Update Vendor',
          component: UpdateVendor
        }, 
        {
          path: 'view_vendor/:id',
          name: 'View Vendor',
          component: ViewVendor
        }, 
        {
          path: 'personnel',
          name: 'Personnel',
          component: Personnel
        },
        {
          path: 'create_personnel',
          name: 'Create Personnel',
          component: CreatePersonnel
        }, 
        {
          path: 'update_personnel/:id',
          name: 'Update Personnel',
          component: UpdatePersonnel
        }, 
        {
          path: 'view_personnel/:id',
          name: 'View Personnel',
          component: ViewPersonnel
        },
        {
          path: 'user',
          name: 'Users',
          component: UserComp
        },
        {
          path: 'personnel_rate',
          name: 'Personnel Rate',
          component: PersonnelRate
        },
        {
          path: 'company',
          name: 'Company',
          component: Company
        },
        {
          path: 'client_rate',
          name: 'Client Trucker Rates',
          component: ClientRate
        }
      ]
    },
    {
      path: '/settings',
      redirect: '/settings',
      name: 'Settings',
      beforeEnter: (to, from, next) => {
        if(window.localStorage.getItem('user_data'))
          next();
        else
        next({name: 'Login'})
      },
      component:  TheContainer,
      children: [
        {
          path: 'vendor_type',
          name: 'Vendor Type',
          component: VendorType
        }, 
        {
          path: 'booking_type',
          name: 'Booking Type',
          component: BookingType
        }, 
        {
          path: 'booking_item_type',
          name: 'Booking Item Type',
          component: BookingItemType
        }, 
        {
          path: 'personnel_type',
          name: 'Personnel Type',
          component: PersonnelType
        },
        {
          path: 'commodity_type',
          name: 'Commodity Type',
          component: CommodityType
        }, 
        {
          path: 'vehicle_type',
          name: 'Vehicle Type',
          component: VehicleType
        }, 
        {
          path: 'depot',
          name: 'Depot',
          component: Depot
        }, 
        {
          path: 'origins',
          name: 'Origins',
          component: Origins
        }, 
        {
          path: 'renewal_task',
          name: 'RenewalTask',
          component: RenewalTask
        }, 
        {
          path: 'service_task',
          name: 'ServiceTask',
          component: ServiceTask
        }, 
        {
          path: 'license_renewal_task',
          name: 'LicenseRenewalTask',
          component: LicenseRenewalTask
        }, 
        {
          path: 'destination',
          name: 'Destination',
          component: Destinations
        }, 
        {
          path: 'maker_model',
          name: 'Maker Model',
          component: MakerModel
        }, 
        {
          path: 'vehicle_model',
          name: 'Vehicle Model',
          component: VehicleModel
        },  
        {
          path: 'part_category',
          name: 'Part Category',
          component: PartCategory
        }, 
        {
          path: 'part_location',
          name: 'Part Location',
          component: PartLocation
        }, 
        {
          path: 'user_type',
          name: 'User Type',
          component: UserType
        }, 
        {
          path: 'permission/:id',
          name: 'Permission',
          component: Permission
        }, 
      ]
    }, 
    {
      path: '/main',
      redirect: '/main',
      beforeEnter: (to, from, next) => {
        if(window.localStorage.getItem('user_data')) {
          console.log('%c BOOKING','color:red')
          next();
        }
        else
        next({name: 'Login'})
      },
      name: 'Booking',
      component:  TheContainer,
      children: [
        // {
        //   path: 'booking',
        //   name: 'Bookings',
        //   component: Booking
        // },
        {
          path: 'booking',
          name: 'Bookings',
          component: BookingV2
        },
        
        {
          path: 'create_booking',
          name: 'Create Booking',
          component: CreateBooking
        },
        {
          path: 'create_booking/:id/:rebook',
          name: 'Recreate Booking',
          component: CreateBooking
        },
        {
          path: 'view_booking/:id',
          name: 'View Booking',
          component: ViewBookingV2
        },
        // {
        //   path: 'view_bookingv2/:id',
        //   name: 'View Booking',
        //   component: ViewBookingV2
        // },
        {
          path: 'update_booking/:id',
          name: 'Update Booking',
          component: UpdateBooking
        }, 
        {
          path: 'report_booking',
          name: 'Report Booking',
          component: ReportBooking
        },

        
        {
          path: 'client_billing',
          name: 'Client Billing',
          component: ClientBilling
        },
        {
          path: 'create_client_billing',
          name: 'Create Client Billing',
          component: CreateClientBilling
        },
        {
          path: 'view_client_billing/:id',
          name: 'View Client Billing',
          component: ViewClientBilling
        },
        {
          path: 'update_client_billing/:id',
          name: 'Update Client Billing',
          component: UpdateClientBilling
        },

        {
          path: 'trucker_settlement',
          name: 'Trucker Settlement',
          component: TruckerSettlement
        },
        {
          path: 'create_trucker_settlement',
          name: 'Create Trucker Settlement',
          component: CreateTruckerSettlement
        },
        {
          path: 'view_trucker_settlement/:id',
          name: 'View Trucker Settlement',
          component: ViewTruckerSettlement
        },
        {
          path: 'update_trucker_settlement/:id',
          name: 'Update Trucker Settlement',
          component: UpdateTruckerSettlement
        },

        {
          path: 'equipment',
          name: 'Equipment',
          component: Equipment
        },
        {
          path: 'create_equipment',
          name: 'Create Equipment',
          component: CreateEquipment
        },
        {
          path: 'view_equipment/:id',
          name: 'View Equipment',
          component: ViewEquipment
        },
        {
          path: 'update_equipment/:id',
          name: 'Update Equipment',
          component: UpdateEquipment
        },

        {
          path: 'furnishing',
          name: 'Furnishing',
          component: Furnishing
        },
        {
          path: 'create_furnishing',
          name: 'Create Furnishing',
          component: CreateFurnishing
        },
        {
          path: 'view_furnishing/:id',
          name: 'View Furnishing',
          component: ViewFurnishing
        },
        {
          path: 'update_furnishing/:id',
          name: 'Update Furnishing',
          component: UpdateFurnishing
        },
        {
          path: 'incident',
          name: 'Incident',
          component: Incident
        },
        {
          path: 'create_incident',
          name: 'Create Incident',
          component: CreateIncident
        }, 
        {
          path: 'create_incident/:id/:view?',
          name: 'View Incident',
          component: CreateIncident
        }, 
        {
          path: 'view_incident/:id',
          name: 'View Incident',
          component: ViewIncident
        },
        {
          path: 'update_incident/:id',
          name: 'Update Incident',
          component: CreateIncident
        }, 
        {
          path: 'parts_inventory',
          name: 'Parts Inventory',
          component: PartsInventory
        },
        {
          path: 'create_parts_inventory',
          name: 'Create Parts Inventory',
          component: CreatePartsInventory
        },
        {
          path: 'update_parts_inventory/:id',
          name: 'Update Vehicle',
          component: UpdatePartsInventory
        }, 
        {
          path: 'view_parts_inventory/:id',
          name: 'View Parts Inventory',
          component: ViewPartsInventory
        },
        {
          path: 'work_order',
          name: 'Work Order',
          component: WorkOrder
        },
        {
          path: 'create_work_order',
          name: 'Create Work Order',
          component: CreateWorkOrder
        },
        {
          path: 'update_work_order/:id',
          name: 'update Work Order',
          component: UpdateWorkOrder
        },
        {
          path: 'update_work_order_trucking/:id',
          name: 'update Work Order Trucking',
          component: UpdateWorkOrderTrucking
        },
        {
          path: 'view_work_order/:id',
          name: 'View Work Order',
          component: ViewWorkOrder
        },
        {
          path: 'view_work_order_trucking/:id',
          name: 'View Work Order Trucking',
          component: ViewWorkOrderTrucking
        },
        {
          path: 'reports',
          name: 'Reports',
          component: Reports,
        },
        {
          path: 'equipment_reports',
          name: 'Reports Equipment',
          component: ReportEquipment,
        },
        {
          path: 'dr_reports',
          name: 'Reports DR',
          component: ReportDr,
        },
        {
          path: 'mts_reports',
          name: 'Reports MTS',
          component: ReportMts,
        },
        {
          path: 'personnel_settlement_reports',
          name: 'Personnel Settlement Report',
          component: ReportPersonnelSettlement,
        },
        {
          path: 'vehicle_reports',
          name: 'Reports Vehicle',
          component: ReportVehicle,
        },
        {
          path: 'furnishing_reports',
          name: 'Reports Furnishing',
          component: ReportFurnishing,
        },
        {
          path: 'workorder_reports',
          name: 'Reports Work Order',
          component: ReportWorkOrder,
        },
        {
          path: 'client_billing_summary_report',
          name: 'Reports client billing summary',
          component: ReportClientBillingSummary,
        },
        {
          path: 'booking_charges_reports',
          name: 'Booking Charges Report',
          component: ReportBookingCharges,
        },
        {
          path: 'opex_reports',
          name: 'Opex Report',
          component: ReportOpex,
        },
            {
              path: 'client_billing',
              name: 'Client Billing',
              component: ClientBilling
            },
            {
              path: 'create_client_billing',
              name: 'Create Client Billing',
              component: CreateClientBilling
            },
            {
              path: 'view_client_billing/:id',
              name: 'View Client Billing',
              component: ViewClientBilling
            },
            {
              path: 'client_billing_payment_receive/:id',
              name: 'Client Billing Payment Receive',
              component: ClientBillingPayment
            },
            {
              path: 'update_client_billing/:id',
              name: 'Update Client Billing',
              component: UpdateClientBilling
            },

            {
              path: 'adjustment',
              name: 'Adjustment',
              component: Adjustment
            },
            {
              path: 'working_calendar',
              name: 'Working Calendar',
              component: WorkingCalendar
            },
            {
              path: '13_month',
              name: '13 Month',
              component: Month13
            },
            {
              path: 'view_13th_month/:id/:year/:type',
              name: 'View 13th Month',
              component: View13thMonth
            },
            {
              path: 'SSS_table',
              name: 'SSS Table Contribution',
              component: SSSTable
            },
            {
              path: 'personnel_ledger',
              name: 'Personnel Ledger',
              component: PLedger
            },
            
            {
              path: 'view_personnel_payment/:id',
              name: 'View Payment History',
              component: ViewPayment
            },
            {
              path: 'view_personnel_ledger/:id',
              name: 'View Personnel Ledger',
              component: ViewPledger
            },
            {
              path: 'payroll',
              name: 'Payroll',
              component: Payroll
            },
            // {
            //   path: 'view_payroll/:id',
            //   name: 'View Payroll',
            //   component: ViewPayroll
            // },
            {
              path: 'view_payroll_personnel/:payroll_id/:personnel_id',
              name: 'View Personnel Payroll',
              component: ViewPayrollPersonnel
            },
            {
              path: 'new_payroll/:id?',
              name: 'New Payroll',
              component: NewPayroll
            },
            {
              path: 'overtime_late',
              name: 'Overtime / Late',
              component: Overtime
            },
            {
              path: 'view_overtime_late/:id',
              name: 'View Overtime / Late',
              component: ViewOvertime
            },
            // {
            //   path: 'create_adjustment',
            //   name: 'Create Adjustment',
            //   component: CreateAdjustment
            // },
            // {
            //   path: 'view_adjustment/:id',
            //   name: 'View Adjustment',
            //   component: ViewAdjustment
            // },
            // {
            //   path: 'update_adjustment/:id',
            //   name: 'Update Adjustment',
            //   component: UpdateAdjustment
            // },
            {
              path: 'logs',
              name: 'Audit Logs',
              component: AuditLog
            },

        
      ]
    },
    {
      path: '/booking/:id',
      redirect: '/booking/:id',
      name: 'Booking',
      beforeEnter: (to, from, next) => {
        if(window.localStorage.getItem('user_data'))
          next();
        else
        next({name: 'Login'})
      },
      component:  TheContainer,
      children: [
        {
          path: 'dr',
          name: 'DR',
          component: DeliveryReceipt
        },
        {
          path: 'create_dr',
          name: 'Create DR',
          component: CreateDeliveryReceipt
        },
        {
          path: 'view_dr/:dr_id',
          name: 'DR',
          component: ViewDeliveryReceipt
        },
        {
          path: 'update_dr/:dr_id',
          name: 'Update DR',
          component: UpdateDeliveryReceipt
        },
      ]
    },
    {
      path: '/booking/:id',
      redirect: '/booking/:id',
      name: 'Booking',
      beforeEnter: (to, from, next) => {
        if(window.localStorage.getItem('user_data'))
          next();
        else
        next({name: 'Login'})
      },
      component:  TheContainer,
      children: [
        {
          path: 'mts',
          name: 'MTS',
          component: MaterialTransfer
        },
        {
          path: 'create_mts',
          name: 'Create MTS',
          component: CreateMaterialTransfer
        },
        {
          path: 'view_mts/:mts_id',
          name: 'MTS',
          component: ViewMaterialTransfer
        },
        {
          path: 'update_mts/:mts_id',
          name: 'Update MTS',
          component: UpdateMaterialTransfer
        },
      ]
    }
  ]
}

